import { render, staticRenderFns } from "./couponReceiveRecord.vue?vue&type=template&id=3befc21c&scoped=true&"
import script from "./couponReceiveRecord.vue?vue&type=script&lang=js&"
export * from "./couponReceiveRecord.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3befc21c",
  null
  
)

export default component.exports